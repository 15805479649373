import React, { Component } from "react";
import { NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class ListFormData extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.getData = this.getData.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.compareBy = this.compareBy.bind(this);
    this.state = {
      userData: [],
      open: false,
      nameAndId: {}
    };
  }

  async getData() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/auth/all`,

      headers: { Authorization: `Bearer ${token}` }
    });

    this.setState({
      userData: response.data.data,
      token: token
    });
    console.log(this.state.userData);
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  ModalPopUp(data) {
    this.setState({
      open: !this.state.open,
      nameAndId: data
    });
    console.log(data);
  }

  deleteUser = async id => {
    let token = this.state.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/auth/delete/${id}`, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.getData();
      this.toggle();
    }
  };

  compareBy(key, asc) {
    if (asc) {
      return function(a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      };
    } else {
      return function(a, b) {
        if (a[key] < b[key]) return 1;
        if (a[key] > b[key]) return -1;
        return 0;
      };
    }
  }

  sortBy(key, asc) {
    let arrayCopy = [...this.state.userData];
    arrayCopy.sort(this.compareBy(key, asc));
    this.setState({ userData: arrayCopy });
  }

  RenderTable = () => {
    let buttonCss = {
      backgroundColor: "rgba(193,251,255,0)",
      border: "0px",
      marginLeft: "5px"
    };

    let adminStyle = { backgroundColor: "rgba(255,10,10,0.5)" };
    let hospStyle = { backgroundColor: "#4bcffa" };
    let chapStyle = { backgroundColor: "#ffda79" };
    return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Active Users</h6>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  #
                </th>
                <th scope="col" className="border-0">
                  Name
                  <button
                    onClick={() => this.sortBy("name", true)}
                    style={buttonCss}
                  >
                    <i class="fas fa-arrow-circle-up"></i>
                  </button>
                  <button
                    onClick={() => this.sortBy("name", false)}
                    style={buttonCss}
                  >
                    <i class="fas fa-arrow-circle-down"></i>
                  </button>
                </th>
                <th scope="col" className="border-0">
                  Email
                  <button
                    onClick={() => this.sortBy("email", true)}
                    style={buttonCss}
                  >
                    <i class="fas fa-arrow-circle-up"></i>
                  </button>
                  <button
                    onClick={() => this.sortBy("email", false)}
                    style={buttonCss}
                  >
                    <i class="fas fa-arrow-circle-down"></i>
                  </button>
                </th>
                <th scope="col" className="border-0">
                  Hospital
                  <button
                    onClick={() => this.sortBy("hospital", true)}
                    style={buttonCss}
                  >
                    <i class="fas fa-arrow-circle-up"></i>
                  </button>
                  <button
                    onClick={() => this.sortBy("hospital", false)}
                    style={buttonCss}
                  >
                    <i class="fas fa-arrow-circle-down"></i>
                  </button>
                </th>
                <th scope="col" className="border-0">
                  Role
                  <button
                    onClick={() => this.sortBy("role", true)}
                    style={buttonCss}
                  >
                    <i class="fas fa-arrow-circle-up"></i>
                  </button>
                  <button
                    onClick={() => this.sortBy("role", false)}
                    style={buttonCss}
                  >
                    <i class="fas fa-arrow-circle-down"></i>
                  </button>
                </th>
                <th scope="col" className="border-0">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.userData.map((data, no) => {
                return (
                  <tr>
                    <td>{no + 1}</td>
                    <td>{data.name}</td>
                    <td>{data.email}</td>
                    <td>{data.hospital}</td>
                    <td
                      style={
                        data.role === "sub_coordinator"
                          ? hospStyle
                          : data.role === "coordinator"
                          ? chapStyle
                          : adminStyle
                      }
                    >
                      {data.role === "sub_coordinator" ? "Hospital" : data.role}
                    </td>
                    <td>
                      <Link to={`userdata/${data._id}`}>
                        <Button theme="success" className="mb-2 mr-1">
                          Edit <i class="fas fa-edit"></i>
                        </Button>
                      </Link>

                      <Button
                        theme="danger"
                        className="mb-2 mr-1"
                        onClick={() => this.ModalPopUp(data)}
                      >
                        Delete <i class="fas fa-trash-alt"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <Modal open={this.state.open} toggle={this.toggle}>
              <ModalHeader>
                Are you sure you want to delete this item?
              </ModalHeader>
              <ModalBody>
                {this.state.nameAndId.name} will be deleted <br />
                <Button
                  theme="danger"
                  className="mb-2 mr-1"
                  onClick={() => this.deleteUser(this.state.nameAndId._id)}
                >
                  Yes, Delete
                </Button>
                <Button
                  theme="success"
                  className="mb-2 mr-1"
                  onClick={this.toggle}
                >
                  Cancel
                </Button>
              </ModalBody>
            </Modal>
          </div>
        </CardBody>
      </Card>
    );
  };

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4"></Row>
        <Row>
          <Col md="12">
            <this.RenderTable />
          </Col>
        </Row>
      </Container>
    );
  };

  componentDidMount() {
    this.getData();
    console.log(this.props.match.params.id);
  }

  render() {
    return this.AddNewPost();
  }
}
