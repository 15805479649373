import React, { Component } from "react";
import { Row, Col, CardBody, CardHeader, Card } from "shards-react";
import ReactLoading from "react-loading";

import "./css/dashbordCard.css";

import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class dashbordTable extends Component {
  constructor(props) {
    super(props);
    this.getBasicSts = this.getBasicSts.bind(this);
    this.DataTable = this.DataTable.bind(this);
    this.HospTable = this.HospTable.bind(this);

    this.getHospData = this.getHospData.bind(this);

    this.state = {
      isLoaded: false,
      isLoaded2: false,
      hospData: [],
      allHospData: []
      // chapterId:""
    };
  }

  async getBasicSts() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const rawData = Cookie.get("userData") ? Cookie.get("userData") : null;
    const userData = JSON.parse(rawData);
    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/table-data`,

      headers: { Authorization: `Bearer ${token}` }
    });

    this.setState({
      userData: response.data.data,
      isLoaded: true
    });
    this.getHospData(userData._id, userData.name);
    //console.log(response.data.data);
  }

  componentDidMount() {
    this.getBasicSts();
  }

  async getHospData(id, name) {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/hosp-data/${id}`,

      headers: { Authorization: `Bearer ${token}` }
    });

    this.setState({
      hospData: response.data.data,
      //isLoaded2: true,
      chapterName: name
    });

    let response2 = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/allhosp-data`,

      headers: { Authorization: `Bearer ${token}` }
    });

    console.log(response2.data.data);

    this.setState({
      allHospData: response2.data.data,
      isLoaded2: true,
      chapterName: name
    });
  }

  DataTable = () => {
    let buttonCss = {
      backgroundColor: "rgba(193,251,255,0)",
      border: "0px",
      marginLeft: "5px",
      color: "#F4597D"
    };

    let adminStyle = { color: "#F4597D" };
    let cardStyle = { backgroundColor: "#F4597D" };

    return (
      <Card small className="mb-4 mt-5">
        <CardHeader className="border-bottom card1">
          <h6 className="m-0">Chapters</h6>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table table-hover table-responsive-sm mb-0">
            <thead className="bg-light">
              <tr style={adminStyle}>
                <th scope="col" className="border-0">
                  #
                </th>
                <th scope="col" className="border-0">
                  Name
                </th>
                <th scope="col" className="border-0">
                  Total Hospitals
                </th>
                <th scope="col" className="border-0">
                  Total Patients
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.userData.map((data, no) => {
                return (
                  <tr
                    onClick={() =>
                      this.getHospData(data._id, data.data[0].name)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <td>{no + 1}</td>
                    <td>{data.data[0].name}</td>
                    <td>{data.HospCount}</td>
                    <td>{data.count}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    );
  };

  HospTable = () => {
    let buttonCss = {
      backgroundColor: "rgba(193,251,255,0)",
      border: "0px",
      marginLeft: "5px",
      color: "#F4597D"
    };

    let adminStyle = { color: "#F4597D" };
    let cardStyle = { backgroundColor: "#F4597D" };
    if (this.state.hospData.length > 0) {
      return (
        <Card small className="mb-4 mt-5">
          <CardHeader className="border-bottom " style={cardStyle}>
            <h6 className="m-0">
              {this.state.isLoaded2
                ? this.state.chapterName
                : "Select a chapter"}
            </h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table table-responsive-sm mb-0">
              <thead className="bg-light">
                <tr style={adminStyle}>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>

                  <th scope="col" className="border-0">
                    Total Patients
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.hospData.map((data, no) => {
                  return (
                    <tr>
                      <td>{no + 1}</td>
                      <td>{data.data[0].name}</td>
                      <td>{data.count}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
        </Card>
      );
    } else {
      return (
        <Card small className="mb-4 mt-5">
          <CardHeader className="border-bottom  " style={cardStyle}>
            <h6 className="m-0">
              {this.state.chapterId
                ? this.state.chapterId
                : this.props.userRole == "coordinator"
                ? "No Active Hospitals Found  "
                : "Select a chapter"}
            </h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <h4
              style={{
                textAlign: "center",
                margin: "100px"
              }}
            >
              {this.state.chapterId
                ? this.state.chapterId
                : this.props.userRole == "coordinator"
                ? "No Active Hospitals Found "
                : "Select a chapter"}
            </h4>
          </CardBody>
        </Card>
      );
    }
  };

  AllHospTable = () => {
    let buttonCss = {
      backgroundColor: "rgba(193,251,255,0)",
      border: "0px",
      marginLeft: "5px",
      color: "#F4597D"
    };

    let adminStyle = { color: "#F4597D" };
    let cardStyle = { backgroundColor: "#F4597D" };
    if (this.state.allHospData.length > 0) {
      return (
        <Card small className="mb-4 mt-5">
          <CardHeader className="border-bottom " style={cardStyle}>
            <h6 className="m-0">Total Hospitals</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table table-responsive-sm mb-0">
              <thead className="bg-light">
                <tr style={adminStyle}>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>

                  <th scope="col" className="border-0">
                    Total Patients
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.allHospData.map((data, no) => {
                  return (
                    <tr>
                      <td>{no + 1}</td>
                      <td>{data.name}</td>
                      <td>{data.patientsCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
        </Card>
      );
    } else {
      return (
        <Card small className="mb-4 mt-5">
          <CardHeader className="border-bottom  " style={cardStyle}>
            <h6 className="m-0">Total Hospitals</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <h2
              style={{
                textAlign: "center",
                margin: "100px"
              }}
            >
              Total Hospitals
            </h2>
          </CardBody>
        </Card>
      );
    }
  };

  Loading() {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          marginLeft: "40%"
        }}
      >
        <Row className="justify-content-md-center">
          <Col lg="2">
            <ReactLoading type={"spinningBubbles"} color={"#F4597D"} />
            {/* <h2>Loading...</h2> */}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    if (this.state.isLoaded) {
      if (
        this.props.userRole == "coordinator" &&
        this.props.userRole === "sub_coordinator"
      ) {
        return (
          <>
            <Row>
              <Col lg="6" md="6">
                <this.AllHospTable />
              </Col>
              <Col lg="6" md="6">
                <this.HospTable />
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row>
              <Col lg="6" md="12">
                <this.DataTable />
              </Col>
              <Col lg="6" md="12">
                <this.HospTable />
              </Col>
            </Row>
          </>
        );
      }
    } else {
      return <this.Loading />;
    }
  }
}
