import React, { Component } from "react";
import { NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Card,
  CardFooter,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class ListFormData extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.getData = this.getData.bind(this);
    this.toggle2 = this.toggle2.bind(this);

    this.state = {
      limit: 100,
      page: 1,
      patientData: [],
      open: false,
      nameAndId: {
        fields: {}
      },
      nameAndId2: {
        fields: {}
      },
      countDetails: {},
      originalData: []
    };
  }

  toggle2() {
    this.setState({
      open2: !this.state.open2
    });
  }

  ModalPopUp2(data) {
    this.setState({
      open2: !this.state.open2,
      nameAndId2: data
    });
  }

  async getData(p) {
    // console.log(l, p);
    let page = p ? p : this.state.page;

    let limit = this.state.limit;

    let searchText = this.state.searchText
      ? `searchText=${this.state.searchText}`
      : null;

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/getdatalist/${page}/${limit}?${searchText}`,

      headers: { Authorization: `Bearer ${token}` }
    });

    this.setState({
      countDetails: response.data.countDetails,
      patientData: response.data.data,
      token: token,
      page: page
    });
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  ModalPopUp(data) {
    this.setState({
      open: !this.state.open,
      nameAndId: data
    });
  }

  deletePatient = async id => {
    let token = this.state.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/forms/delete/${id}`, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.getData();
      this.toggle();
    }
  };

  ShowSts(data) {
    if (data.data) {
      return <div>Completed</div>;
    } else {
      return <div>Not Completed</div>;
    }
  }

  RenderTable = () => {
    return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <this.SearchBar />
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  #
                </th>
                <th scope="col" className="border-0">
                  Patient Id
                </th>
                <th scope="col" className="border-0">
                  Hospital Id
                </th>
                <th scope="col" className="border-0">
                  Cardiologist
                </th>

                <th scope="col" style={{ width: "30%" }} className="border-0">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.patientData.map((data, no) => {
                return (
                  <tr>
                    <td>{(this.state.page - 1) * this.state.limit + no + 1}</td>
                    <td>{data.fields.patient_id_819}</td>
                    <td>{data.fields.hospital_id_998}</td>
                    <td>
                      {data.fields.name_of_the_cardiologist_in_charge_611}
                    </td>
                    <td>
                      <Row>
                        <Col>
                          <Link to={`patientdata/${data._id}`}>
                            <Button theme="success" className="mb-2 mr-1">
                              Edit <i class="fas fa-edit"></i>
                            </Button>
                          </Link>
                        </Col>

                        <Col>
                          <Button
                            theme="danger"
                            className="mb-2 mr-1"
                            onClick={() => this.ModalPopUp(data)}
                          >
                            Delete <i class="fas fa-trash-alt"></i>
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>

        <CardFooter>
          <this.Pagination />
        </CardFooter>
        <div>
          <Modal open={this.state.open} toggle={this.toggle}>
            <ModalHeader>
              Are you sure you want to delete this item?
            </ModalHeader>
            <ModalBody>
              {this.state.nameAndId.fields.name} will be deleted <br />
              <Button
                theme="danger"
                className="mb-2 mr-1"
                onClick={() => this.deletePatient(this.state.nameAndId._id)}
              >
                Yes, Delete
              </Button>
              <Button
                theme="success"
                className="mb-2 mr-1"
                onClick={this.toggle}
              >
                Cancel
              </Button>
            </ModalBody>
          </Modal>
        </div>

        <div>
          <Modal open={this.state.open2} size="lg" toggle={this.toggle2}>
            <ModalHeader>Follow Up Details</ModalHeader>
            <ModalBody>
              <br />
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Follow up
                    </th>
                    <th scope="col" className="border-0">
                      Status
                    </th>
                    <th scope="col" className="border-0">
                      Add
                    </th>

                    <th scope="col" className="border-0">
                      Edit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st Month </td>
                    <td>
                      <this.ShowSts data={this.state.nameAndId2.followUp1Sts} />
                    </td>
                    <td>
                      <Link
                        to={`add-followdata/${this.state.nameAndId2._id}/1`}
                      >
                        <Button
                          theme="success"
                          className="mb-2 mr-1"
                          disabled={this.state.nameAndId2.followUp1Start}
                        >
                          Add <i class="fas fa-plus"></i>
                        </Button>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`edit-followdata/${this.state.nameAndId2._id}/1`}
                      >
                        <Button
                          theme="warning"
                          className="mb-2 mr-1"
                          disabled={!this.state.nameAndId2.followUp1Start}
                        >
                          Edit <i class="fas fa-edit"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>3rd Month </td>
                    <td>
                      {" "}
                      <this.ShowSts data={this.state.nameAndId2.followUp3Sts} />
                    </td>
                    <td>
                      <Link
                        to={`add-followdata/${this.state.nameAndId2._id}/3`}
                      >
                        <Button
                          theme="success"
                          className="mb-2 mr-1"
                          disabled={this.state.nameAndId2.followUp3Start}
                        >
                          Add <i class="fas fa-plus"></i>
                        </Button>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`edit-followdata/${this.state.nameAndId2._id}/3`}
                      >
                        <Button
                          theme="warning"
                          className="mb-2 mr-1"
                          disabled={!this.state.nameAndId2.followUp3Start}
                        >
                          Edit <i class="fas fa-edit"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>6th Month </td>
                    <td>
                      {" "}
                      <this.ShowSts data={this.state.nameAndId2.followUp6Sts} />
                    </td>
                    <td>
                      <Link
                        to={`add-followdata/${this.state.nameAndId2._id}/6`}
                      >
                        <Button
                          theme="success"
                          className="mb-2 mr-1"
                          disabled={this.state.nameAndId2.followUp6Start}
                        >
                          Add <i class="fas fa-plus"></i>
                        </Button>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`edit-followdata/${this.state.nameAndId2._id}/6`}
                      >
                        <Button
                          theme="warning"
                          className="mb-2 mr-1"
                          disabled={!this.state.nameAndId2.followUp6Start}
                        >
                          Edit <i class="fas fa-edit"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>12 Month </td>
                    <td>
                      {" "}
                      <this.ShowSts data={this.state.nameAndId2.followUp6Sts} />
                    </td>
                    <td>
                      <Link
                        to={`add-followdata/${this.state.nameAndId2._id}/12`}
                      >
                        <Button
                          theme="success"
                          className="mb-2 mr-1"
                          disabled={this.state.nameAndId2.followUp12Start}
                        >
                          Add <i class="fas fa-plus"></i>
                        </Button>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`edit-followdata/${this.state.nameAndId2._id}/12`}
                      >
                        <Button
                          theme="warning"
                          className="mb-2 mr-1"
                          disabled={!this.state.nameAndId2.followUp12Start}
                        >
                          Edit <i class="fas fa-edit"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
          </Modal>
        </div>
      </Card>
    );
  };

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="List Form"
            subtitle="List Form Fields"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col md="12">
            <this.RenderTable />
          </Col>
        </Row>
      </Container>
    );
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === "limit") {
      this.getData(e.target.value);
    } else if (e.target.name === "nextBtn") {
      this.setState({
        page: this.state.page + 1
      });
      this.getData();
    } else if (e.target.name === "prevBtn" && this.state.page > 0) {
      this.setState({
        page: this.state.page - 1
      });
      this.getData();
    }
  };

  SearchBar = () => {
    return (
      <>
        <Row>
          <Col md="6" className="form-group">
            <FormInput
              id="parentValue"
              type="text"
              placeholder="Search By Patient ID or Hospital Id or Cardiologist Name"
              name="searchText"
              onChange={this.handleChange}
            />
          </Col>
          <Col lg="1">
            <Button
              onClick={() => this.getData(1)}
              name="prevBtn"
              theme="warning"
              className="mb-2 mr-1"
            >
              Search
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  Pagination = () => {
    let totalPages = 0;
    let totalCount = 0;
    let perPage = this.state.limit;
    let currentPage = this.state.page;
    let hasNextPage = false;
    if (this.state.countDetails) {
      totalCount = this.state.countDetails.totalCount;
      totalPages = this.state.countDetails.totalPages;
      perPage = this.state.countDetails.perPage;
      hasNextPage = this.state.countDetails.hasNextPage;
    }
    let pageArr = [...Array(totalPages).keys()];

    return (
      <Row>
        Pages : &nbsp;&nbsp;&nbsp;
        {pageArr.map((item, key) => {
          if (currentPage === key + 1) {
            return (
              <div>
                <Button onClick={() => this.getData(key + 1)} pill>
                  {key + 1}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </div>
            );
          } else {
            return (
              <div>
                <Button onClick={() => this.getData(key + 1)} outline pill>
                  {key + 1}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </div>
            );
          }
        })}
      </Row>
    );
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    return this.AddNewPost();
  }
}
