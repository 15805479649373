import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  FormSelect,
  FormInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormRadio,
  FormCheckbox,
  Modal,
  ModalBody,
  ModalHeader,
  Button
} from "shards-react";
import ReactLoading from "react-loading";

import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddFormData extends Component {
  constructor(props) {
    super(props);

    this.getFormFields = this.getFormFields.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.RenderFields = this.RenderFields.bind(this);
    this.firstSend = this.firstSend.bind(this);
    this.nextData = this.nextData.bind(this);
    this.prevData = this.prevData.bind(this);
    this.BottomButton = this.BottomButton.bind(this);
    this.CustomRadio = this.CustomRadio.bind(this);
    this.CustomCheckBox = this.CustomCheckBox.bind(this);
    this.bmiCal = this.bmiCal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.pesiCalk = this.pesiCalk.bind(this);
    this.ClearArr = this.ClearArr.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.uploadLoading = this.uploadLoading.bind(this);
    this.redirectState = this.redirectState.bind(this);

    this.state = {
      formFields: [],
      fieldValue: {},
      checkBoxValue: [],
      sliceArray: [],
      start: 0,
      end: 1,
      id: null,
      selectedFile: false,
      open: false,
      redirect: false,
      isLoading: false
    };
  }

  async getFormFields() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .get(`${URL.BASE_URL}/fields/get`, config)
      .catch(function(error) {
        console.log(error);
        this.setState({
          error: error
        });
      });
    if (response) {
      this.setState({
        formFields: response.data.data
      });
      //console.log(response.data.data);

      let fields = [];
      let sliceNo = [];

      fields = response.data.data;

      fields.forEach((item, key) => {
        // console.log(key, item.fieldType);
        if (item.fieldType === "title") {
          sliceNo.push(key);
        }
      });
      sliceNo.push(fields.length);

      this.setState({
        sliceArray: sliceNo
      });

      //------

      let id = this.props.match.params.id;
      let response2 = await axios({
        method: "get",
        url: `${URL.BASE_URL}/forms/singledata/${id}`,

        headers: { Authorization: `Bearer ${token}` }
      });

      this.setState({
        fieldValue: response2.data.data.fields,
        token: token
      });
      console.log(this.state.fieldValue);
      //console.log(response.data.data);
    }
  }

  async fileUpload(e) {
    let name = e.target.name;
    this.setState({
      selectedFile: true
    });
    const data = new FormData();
    data.append("image", e.target.files[0]);

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let response = await axios
      .post(`${URL.BASE_URL}/forms/files`, data, config)
      .catch(function(error) {
        console.log(error);
      });

    if (!response.data.error && response.data.statusCode === 200) {
      this.setState({
        fieldValue: { [name]: response.data.filename },

        selectedFile: false
      });
    }
    //console.log(name);
  }

  uploadLoading({ slug }) {
    var fileName;
    fileName = this.state.fieldValue[slug];
    if (this.state.selectedFile) {
      return (
        <>
          <Row
            style={{
              marginTop: "25px"
            }}
          >
            <Col md="1">
              <ReactLoading
                type={"spin"}
                color={"#5af"}
                height={"25px"}
                width={"25px"}
              />
            </Col>
            <Col md="3">
              <strong>Uploading...</strong>
            </Col>
          </Row>
        </>
      );
    } else {
      if (fileName) {
        return (
          <div>
            <a href={`${URL.BASE_URL}/${fileName}`} target="_blank">
              View File
            </a>
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }

  handleChange(e) {
    var merged;
    let newItem;
    let oldItem = this.state.fieldValue;

    newItem = { [e.target.name]: e.target.value };
    merged = { ...oldItem, ...newItem };
    this.setState({
      fieldValue: merged
    });
    //console.log(this.state.fieldValue);
  }

  handleCheck(e) {
    let item = e.target.name;
    let value = e.target.value;
    let newItem = [];

    if (e.target.checked) {
      if (this.state.fieldValue[item]) {
        newItem = this.state.fieldValue[item];
      }

      newItem.push(value);
    } else {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      newItem.pop(value);
    }

    this.setState({
      [item]: newItem
    });

    console.log(this.state);
  }

  nextData() {
    let oldStart = this.state.start;
    let oldEnd = this.state.end;
    this.setState({
      start: oldStart + 1,
      end: oldEnd + 1
    });
    window.scrollTo(0, 0);
    this.firstSend();
  }
  prevData() {
    let oldStart = this.state.start;
    let oldEnd = this.state.end;
    this.setState({
      start: oldStart - 1,
      end: oldEnd - 1
    });
    window.scrollTo(0, 0);
  }

  async firstSend(e) {
    //e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let stateData = Object.assign({}, this.state);
    delete stateData.formFields;
    delete stateData.fieldValue;
    delete stateData.checkBoxValue;
    delete stateData.start;
    delete stateData.end;
    delete stateData.sliceArray;
    delete stateData.id;
    delete stateData.selectedFile;
    delete stateData.token;
    let dataToSend = { ...this.state.fieldValue, ...stateData };

    // console.log(dataToSend);
    // console.log("Sening");
    let response;
    if (this.state.start + 1 === this.state.sliceArray.length - 1) {
      dataToSend.isCompleted = true;
      this.setState({
        isCompleted: true
      });
    }
    dataToSend.id = this.props.match.params.id;
    response = await axios
      .patch(`${URL.BASE_URL}/forms/updatedata`, dataToSend, config)
      .catch(function(error) {
        console.log(error);
      });
    // console.log(response);

    //console.log(this.state);
  }

  pagination(pageNo) {
    this.setState({
      isLoading: true
    });

    setTimeout(
      function() {
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );
    if (false) {
      console.log("Validation");
      this.setState({
        validation: true
      });
    } else {
      this.setState({
        validation: false
      });

      this.setState({
        start: pageNo - 1,
        end: pageNo
      });
      window.scrollTo(0, 0);
      console.log(this.state);
      this.firstSend();
    }
  }

  BottomButton() {
    let start = this.state.start;
    let end = this.state.end;
    console.log(this.state.sliceArray, start, end);
    let arrayLength = this.state.sliceArray.length;
    if (start === 0) {
      return (
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              {this.state.sliceArray.map((item, index) => {
                if (
                  index > 0 &&
                  index > this.state.end - 5 &&
                  index < this.state.end + 5
                )
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={this.nextData}
                >
                  Next
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else if (start === arrayLength - 2 && end === arrayLength - 1) {
      return (
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              {this.state.sliceArray.map((item, index) => {
                if (
                  index > 0 &&
                  index > this.state.end - 5 &&
                  index < this.state.end + 5
                )
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="success"
                  className="mb-2 mr-1"
                  onClick={this.redirectState}
                >
                  Submit
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else if (start > 0 && end < arrayLength) {
      return (
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              {this.state.sliceArray.map((item, index) => {
                if (
                  index > 0 &&
                  index > this.state.end - 5 &&
                  index < this.state.end + 5
                )
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={this.nextData}
                >
                  Next
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else {
      return <div>" "</div>;
    }
  }

  CustomRadio({ name, item }) {
    if (item === this.state.fieldValue[name]) {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            defaultChecked
            style={{
              marginTop: "2px",
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
          ></input>
          <div
            style={{
              marginLeft: "15px",
              fontSize: "16px"
            }}
          >
            {item}
          </div>
        </>
      );
    } else {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            style={{
              marginTop: "2px",
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
          ></input>
          <div
            style={{
              marginLeft: "15px",
              fontSize: "16px"
            }}
          >
            {item}
          </div>
        </>
      );
    }
  }

  ClearArr({ name }) {
    console.log(name);
    let object = { ...this.state };
    object.fieldValue[name] = [];
    object[name] = [];
    this.setState(object);
    console.log(this.state[name]);
  }

  CustomCheckBox({ name, item }) {
    let arr = [];
    if (this.state[name]) {
      arr = this.state[name];
    } else if (this.state.fieldValue[name]) {
      arr = this.state.fieldValue[name];
    }
    //console.log(name, item);
    // console.log(arr);
    let isChecked = arr.includes(item);
    //console.log(isChecked);

    return (
      <>
        <Col md="3">
          <input
            type="checkbox"
            defaultChecked={isChecked}
            name={name}
            value={item}
            onChange={this.handleCheck}
          />
          &nbsp; &nbsp; &nbsp;
          {item}
        </Col>
      </>
    );
  }

  bmiCal(h, w) {
    let height = Number(this.state.fieldValue["height_m_825"]);
    let weight = 0; // Number(this.state.fieldValue['weight_kg_328'])
    if (h !== 0) {
      height = h;
    }
    if (w !== 0) {
      weight = w;
    }

    let bmi = (Number(weight) / (Number(height) * Number(height))).toFixed(2);
    console.log(bmi, height, weight, this.state.bmi_w_kgm2_996);
    this.setState({
      bmi_w_kgm2_996: bmi
    });
  }

  pesiCalk() {
    let pesi_score = Number(this.state.fieldValue["age_220"]);
    if (this.state.fieldValue.patient_age_131) {
      pesi_score = Number(this.state.fieldValue.patient_age_131);
    }
    // Number(this.state.pesi_final)
    if (this.state.fieldValue["male_gender_10_points_814"] === "Yes") {
      pesi_score += 10;
    }
    if (this.state.fieldValue["cancer_history_30_points_288"] === "Yes") {
      pesi_score += 10;
    }
    if (this.state.fieldValue["heart_failure_10_points_735"] === "Yes") {
      pesi_score += 10;
    }
    if (this.state.fieldValue["chronic_lung_disease_10_points_657"] === "Yes") {
      pesi_score += 10;
    }
    if (
      this.state.fieldValue["pulse_110_beats_per_min_20_points_432"] === "Yes"
    ) {
      pesi_score += 20;
    }
    if (this.state.fieldValue["sbp_100_mm_hg_30_points_139"] === "Yes") {
      pesi_score += 30;
    }
    if (
      this.state.fieldValue["respiratory_rate_30_per_min_20_points_775"] ===
      "Yes"
    ) {
      pesi_score += 20;
    }
    if (
      this.state.fieldValue["temperature_36_degree_celsius_20_points_630"] ===
      "Yes"
    ) {
      pesi_score += 20;
    }
    if (
      this.state.fieldValue[
        "disorientation_lethargystupor_coma_60_points_676"
      ] === "Yes"
    ) {
      pesi_score += 60;
    }
    if (this.state.fieldValue["sao2_90_20_points_558"] === "Yes") {
      pesi_score += 20;
    }

    let pesiClass;
    if (pesi_score < 66) {
      pesiClass = "CLASS 1 < 66 Points";
    } else if (pesi_score < 86) {
      pesiClass = "CLASS 2 < 66 - 85 Points";
    } else if (pesi_score < 106) {
      pesiClass = "CLASS 3 < 86 - 105 Points";
    } else if (pesi_score < 126) {
      pesiClass = "CLASS 4 < 106 - 125 Points";
    } else if (pesi_score > 125) {
      pesiClass = "CLASS 5 > 125 Points";
    }

    this.setState({
      pesi_final: pesi_score,
      pesi_cls: pesiClass
    });
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  RenderFields() {
    let fields = [];

    if (this.state.formFields) {
      fields = this.state.formFields;
    }

    let start = 0;
    let end = this.state.sliceArray[this.state.end];
    if (this.state.start > 0) {
      start = this.state.sliceArray[this.state.start];
    }

    return (
      <div>
        {fields.slice(start, end).map(field => {
          var parent_name;
          if (field.parent) {
            parent_name = this.state.fieldValue[
              fields[field.parent + 1].fieldSlug
            ];
          }

          if (field.parent && parent_name === undefined) {
            parent_name = this.state.fieldValue[
              fields[field.parent + 2].fieldSlug
            ];
          }
          if (field.parent && parent_name === undefined) {
            parent_name = this.state.fieldValue[
              fields[field.parent + 3].fieldSlug
            ];
          }

          // console.log("pos ------>", field.parent);
          //   console.log(" value ------>", parent_name);
          //  console.log(" parent ------>", fields[field.parent - 1]);
          //  console.log("------------------------");

          //for ANA
          if (field.fieldSlug === "nt_pro_bnp_pgml_803") {
            return (
              <>
                <>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="text"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.fieldValue[field.fieldSlug]}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>

                <Row>
                  <Col md="3">{this.state.formFields[275].fieldName} : </Col>
                  {this.state.formFields[275].radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio
                          name={this.state.formFields[275].fieldSlug}
                          item={item}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (
            this.state.fieldValue.postpartum_291 !== "Yes" &&
            field.fieldSlug === "postpartum_duration_days_777"
          ) {
            return <div></div>;
          } else if (
            field.fieldSlug === "subsection_for_vte_421" ||
            field.fieldSlug === "duration_in_months_531" ||
            field.fieldSlug === "ana_353"
          ) {
            return <div></div>;
          } else if (
            this.state.fieldValue.history_of_vte_159 === "Yes" &&
            field.fieldSlug === "history_of_vte_159"
          ) {
            return (
              <>
                <Row>
                  <Col md="4">{field.fieldName} : </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />

                <Row>
                  <Col md="4">{this.state.formFields[273].fieldName} : </Col>
                  {this.state.formFields[273].radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio
                          name={this.state.formFields[273].fieldSlug}
                          item={item}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />

                <Col lg="8" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {this.state.formFields[274].fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="number"
                        name={this.state.formFields[274].fieldSlug}
                        onChange={this.handleChange}
                        value={
                          this.state.fieldValue[
                            this.state.formFields[274].fieldSlug
                          ]
                        }
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          } else if (
            (field.fieldSlug === "ct_pulmonary_angiography_564" ||
              field.fieldSlug === "main_pa_size_mm_377" ||
              field.fieldSlug === "rpa_size_mm_668" ||
              field.fieldSlug === "lpa_size_mm_126" ||
              field.fieldSlug === "site_of_obstruction_811" ||
              field.fieldSlug === "degree_of_obstruction_195" ||
              field.fieldSlug === "ct_pulmonary_angiography_564") &&
            this.state.fieldValue.ct_pulmonary_angiography_done_699 !== "Yes"
          ) {
            return <div></div>;
          } else if (
            field.fieldSlug === "reason_for_cdt_interruption_791" &&
            this.state.fieldValue.if_cdt_interrupted_228 !== "Yes"
          ) {
            return <div></div>;
          } else if (
            field.fieldSlug === "if_surgical_thrombectomy_done_details_213" &&
            this.state.fieldValue
              .whether_emergency_surgical_thrombectomy_done_534 !== "Yes"
          ) {
            return <div></div>;
          } else if (
            this.state.fieldValue.ct_pulmonary_angiography_done_699 === "Yes" &&
            field.fieldSlug === "thrombus_location_158" &&
            this.state.fieldValue.site_of_obstruction_811 === "Unilateral Right"
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <this.CustomCheckBox
                          name={field.fieldSlug}
                          item={item}
                        />
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (
            this.state.fieldValue.ct_pulmonary_angiography_done_699 === "Yes" &&
            field.fieldSlug === "thrombus_location_207" &&
            this.state.fieldValue.site_of_obstruction_811 === "Unilateral Left"
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <this.CustomCheckBox
                          name={field.fieldSlug}
                          item={item}
                        />
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (
            this.state.fieldValue.ct_pulmonary_angiography_done_699 === "Yes" &&
            field.fieldSlug === "thrombus_location_228" &&
            (this.state.fieldValue.site_of_obstruction_811 === "Bilateral" ||
              this.state.fieldValue.site_of_obstruction_811 ===
                "Saddle Embolism")
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <this.CustomCheckBox
                          name={field.fieldSlug}
                          item={item}
                        />
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (
            field.fieldSlug === "if_others_mention_the_symptom_858" &&
            this.state.fieldValue.mention_first_symptom_248 === "others"
          ) {
            return (
              <>
                <Col lg="8" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          } else if (
            (field.fieldSlug === "oac_subsection_751" ||
              field.fieldSlug ===
                "duration_required_to_attain_final_dose_of_oac_days_959" ||
              field.fieldSlug === "which_oac_used_376" ||
              field.fieldSlug === "starting_dose_mg_335" ||
              field.fieldSlug === "final_dose_of_oac_to_attain_inr_mg_590") &&
            this.state.fieldValue.whether_oac_used_663 === "Yes"
          ) {
            if (field.fieldType === "sub_title") {
              return (
                <>
                  <Col md="10">
                    <h5>
                      {" "}
                      <strong> {field.fieldName} </strong>{" "}
                    </h5>
                    <hr />
                  </Col>
                </>
              );
            } else if (field.fieldType === "text") {
              if (field.fieldSlug === "patient_id_439") {
                return (
                  <>
                    <Col lg="8" sm="12">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="text"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue.patient_id_439}
                            disabled
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
              } else
                return (
                  <>
                    <Col lg="8" sm="12">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="text"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue[field.fieldSlug]}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
            } else if (field.fieldType === "dropdown") {
              if (field.fieldSlug === "class_762") {
                return (
                  <>
                    <Col md="3">
                      <label htmlFor="feInputState"> {field.fieldName} </label>:{" "}
                      <FormSelect
                        id="feInputState"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                      >
                        <option>{this.state.pesi_cls}</option>
                        {field.checkBoxEnum.map(item => {
                          return (
                            <>
                              <option>{item}</option>
                            </>
                          );
                        })}
                      </FormSelect>
                    </Col>
                    <hr />
                  </>
                );
              } else
                return (
                  <>
                    <Col md="3">
                      <label htmlFor="feInputState"> {field.fieldName} </label>:{" "}
                      <FormSelect
                        id="feInputState"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                      >
                        <option>
                          {this.state.fieldValue[field.fieldSlug]}
                        </option>
                        {field.checkBoxEnum.map(item => {
                          return (
                            <>
                              <option>{item}</option>
                            </>
                          );
                        })}
                      </FormSelect>
                    </Col>
                    <hr />
                  </>
                );
            } else if (field.fieldType === "number") {
              if (field.fieldSlug === "total_score_705") {
                return (
                  <>
                    <Col lg="8" sm="12">
                      <Button
                        size="sm"
                        theme="warning"
                        className="mb-2 mr-1"
                        onClick={this.pesiCalk}
                      >
                        Calculate PESI Score
                      </Button>
                    </Col>
                    <Col lg="8" sm="12">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.pesi_final}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
              }
              if (field.fieldSlug === "age_220") {
                return (
                  <>
                    <Col lg="8" sm="12">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue.patient_age_131}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
              } else if (field.fieldSlug === "bmi_w_kgm2_996") {
                return (
                  <>
                    <Col lg="8" sm="12">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.bmi_w_kgm2_996}
                            disabled
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
              } else
                return (
                  <>
                    <Col lg="8" sm="12">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue[field.fieldSlug]}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
            }
          } else if (
            (field.fieldSlug === "fibrinolysis_subsection_563" ||
              field.fieldSlug === "what_fibrinolytic_agent_was_used_918" ||
              field.fieldSlug === "dose_of_fibrinolytic_agent_used_763" ||
              field.fieldSlug === "duration_hours_410" ||
              field.fieldSlug === "reason_for_fibrinolysis_interruption_299" ||
              field.fieldSlug === "whether_fibrinolysis_interrupted_679") &&
            this.state.fieldValue.whether_fibrinolytic_agent_used_734 === "Yes"
          ) {
            if (
              field.fieldSlug === "reason_for_fibrinolysis_interruption_299" &&
              this.state.fieldValue.whether_fibrinolysis_interrupted_679 !==
                "Yes"
            ) {
              return <div></div>;
            }

            if (field.fieldType === "sub_title") {
              return (
                <>
                  <Col md="10">
                    <h5>
                      {" "}
                      <strong> {field.fieldName} </strong>{" "}
                    </h5>
                    <hr />
                  </Col>
                </>
              );
            } else if (field.fieldType === "radio") {
              return (
                <>
                  <Row>
                    <Col md="3">{field.fieldName} : </Col>
                    {field.radioEnum.map(item => {
                      return (
                        <>
                          <this.CustomRadio
                            name={field.fieldSlug}
                            item={item}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      );
                    })}
                  </Row>
                  <hr />
                </>
              );
            } else if (field.fieldType === "text") {
              if (field.fieldSlug === "patient_id_439") {
                return (
                  <>
                    <Col lg="8" sm="12">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="text"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue.patient_id_439}
                            disabled
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
              } else
                return (
                  <>
                    <Col lg="8" sm="12">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="text"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue[field.fieldSlug]}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
            } else if (field.fieldType === "dropdown") {
              if (field.fieldSlug === "class_762") {
                return (
                  <>
                    <Col md="3">
                      <label htmlFor="feInputState"> {field.fieldName} </label>:{" "}
                      <FormSelect
                        id="feInputState"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                      >
                        <option>{this.state.pesi_cls}</option>
                        {field.checkBoxEnum.map(item => {
                          return (
                            <>
                              <option>{item}</option>
                            </>
                          );
                        })}
                      </FormSelect>
                    </Col>
                    <hr />
                  </>
                );
              } else
                return (
                  <>
                    <Col md="3">
                      <label htmlFor="feInputState"> {field.fieldName} </label>:{" "}
                      <FormSelect
                        id="feInputState"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                      >
                        <option>
                          {this.state.fieldValue[field.fieldSlug]}
                        </option>
                        {field.checkBoxEnum.map(item => {
                          return (
                            <>
                              <option>{item}</option>
                            </>
                          );
                        })}
                      </FormSelect>
                    </Col>
                    <hr />
                  </>
                );
            }
          } else if (field.parent && parent_name !== "Yes") {
            return <div> </div>;
          } else if (field.fieldType === "text") {
            if (field.fieldSlug === "patient_id_439") {
              return (
                <>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="text"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.fieldValue.patient_id_439}
                          disabled
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
            } else
              return (
                <>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="text"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.fieldValue[field.fieldSlug]}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
          } else if (field.fieldType === "number") {
            if (field.fieldSlug === "total_score_705") {
              return (
                <>
                  <Col lg="8" sm="12">
                    <Button
                      size="sm"
                      theme="warning"
                      className="mb-2 mr-1"
                      onClick={this.pesiCalk}
                    >
                      Calculate PESI Score
                    </Button>
                  </Col>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="number"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.pesi_final}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
            }
            if (field.fieldSlug === "age_220") {
              return (
                <>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="number"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.fieldValue.patient_age_131}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
            } else if (field.fieldSlug === "bmi_w_kgm2_996") {
              return (
                <>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="number"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.bmi_w_kgm2_996}
                          disabled
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
            } else if (field.fieldSlug === "mobile_number_phone_number_1_358") {
              return (
                <>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="number"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.fieldValue[field.fieldSlug]}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
            } else
              return (
                <>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="number"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.fieldValue[field.fieldSlug]}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
          } else if (field.fieldType === "radio") {
            return (
              <>
                <Row>
                  <Col md="5">{field.fieldName} : </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          } else if (field.fieldType === "check_box") {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <this.CustomCheckBox
                          name={field.fieldSlug}
                          item={item}
                        />
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (field.fieldType === "dropdown") {
            if (field.fieldSlug === "class_762") {
              return (
                <>
                  <>
                    <Col md="8">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormSelect
                            id="feInputState"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                          >
                            <option>{this.state.pesi_cls}</option>

                            {field.checkBoxEnum.map(item => {
                              return (
                                <>
                                  <option>{item}</option>
                                </>
                              );
                            })}
                          </FormSelect>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>

                  <hr />
                </>
              );
            } else
              return (
                <>
                  <Col md="8">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormSelect
                          id="feInputState"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                        >
                          <option>
                            {this.state.fieldValue[field.fieldSlug]}
                          </option>
                          {field.checkBoxEnum.map(item => {
                            return (
                              <>
                                <option>{item}</option>
                              </>
                            );
                          })}
                        </FormSelect>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
          } else if (field.fieldType === "date") {
            if (field.fieldSlug === "date_of_discharge_244") {
              return (
                <>
                  <Col>
                    <label htmlFor="date"> {field.fieldName} </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="date"
                      id="date"
                      name={field.fieldSlug}
                      onChange={this.handleChange}
                      min={this.state.fieldValue["date_of_admission_180"]}
                      style={{
                        padding: "5px 5px",
                        margin: "8px 0",
                        "box-sizing": "border-box",
                        color: "#ccc",
                        border: "1px solid #ccc",
                        "border-radius": "4px"
                      }}
                      value={this.state.fieldValue[field.fieldSlug]}
                    />
                  </Col>
                  <hr />
                </>
              );
            } else if (field.fieldSlug === "date_of_death_968") {
              return (
                <>
                  <Col>
                    <label htmlFor="date"> {field.fieldName} </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="date"
                      id="date"
                      name={field.fieldSlug}
                      onChange={this.handleChange}
                      min={this.state.fieldValue["date_of_admission_180"]}
                      style={{
                        padding: "5px 5px",
                        margin: "8px 0",
                        "box-sizing": "border-box",
                        color: "#ccc",
                        border: "1px solid #ccc",
                        "border-radius": "4px"
                      }}
                      value={this.state.fieldValue[field.fieldSlug]}
                    />
                  </Col>
                  <hr />
                </>
              );
            } else
              return (
                <>
                  <Col>
                    <label htmlFor="date"> {field.fieldName} </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="date"
                      id="date"
                      name={field.fieldSlug}
                      onChange={this.handleChange}
                      style={{
                        padding: "5px 5px",
                        margin: "8px 0",
                        "box-sizing": "border-box",
                        color: "#ccc",
                        border: "1px solid #ccc",
                        "border-radius": "4px"
                      }}
                      value={this.state.fieldValue[field.fieldSlug]}
                    />
                  </Col>
                  <hr />
                </>
              );
          } else if (field.fieldType === "title") {
            return (
              <>
                <Col md="10">
                  <h2> {field.fieldName} </h2>
                  <hr />
                </Col>
              </>
            );
          } else if (field.fieldType === "sub_title") {
            return (
              <>
                <Col md="10">
                  <h5>
                    {" "}
                    <strong> {field.fieldName} </strong>{" "}
                  </h5>
                  <hr />
                </Col>
              </>
            );
          } else if (
            field.fieldType === "image" ||
            field.fieldType === "file"
          ) {
            return (
              <>
                {field.fieldName} &nbsp;&nbsp; : &nbsp;&nbsp;
                <Col lg="8" sm="12">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile2"
                    name={field.fieldSlug}
                    onChange={this.fileUpload}
                  />
                  <label className="custom-file-label" htmlFor="customFile2">
                    {this.state[field.fieldSlug]}
                  </label>
                  <this.uploadLoading slug={field.fieldSlug} />
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "document") {
            if (field.fieldSlug === "pesi_score_188") {
              return (
                <div
                  style={{
                    margin: "10px"
                  }}
                >
                  <Row>
                    {/*  <img
                      src="http://csisc2020.org/pesi.jpeg"
                      alt="Logo"
                      width="720px"
                      height="540px"
                    />*/}
                  </Row>
                  <Row>
                    <div
                      style={{
                        margin: "20px",
                        color: "red",
                        fontSize: "20px"
                      }}
                    >
                      <br />
                      <strong>All Fields are Mandatory !</strong>
                    </div>
                  </Row>
                </div>
              );
            } else {
              return (
                <Button
                  outline
                  theme="dark"
                  className="mb-2 mr-1"
                  onClick={this.toggle}
                >
                  For BARC Bleeding Criteria
                </Button>
              );
            }
          }
          //return <li key={field.fieldSlug}>{field.fieldType}</li>;
        })}
        <this.BottomButton />
        <br />
        <strong>
          Steps : {this.state.start + 1} / {this.state.sliceArray.length - 1}
        </strong>

        <div>
          <Modal open={this.state.open} size="lg" toggle={this.toggle}>
            <ModalHeader>
              <Button
                theme="danger"
                className="mb-2 mr-1"
                onClick={this.toggle}
              >
                Close
              </Button>
            </ModalHeader>
            <ModalBody>
              <img
                src="http://csisc2020.org/BARC.png"
                alt="critiria"
                width="140%"
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          {/*
          <PageTitle
            sm="4"
            title="List Form"
            subtitle="List Form Fields"
            className="text-sm-left"
          />*/}
        </Row>
        <Row>
          <Col md="12">
            <this.RenderFields />
          </Col>
        </Row>
      </Container>
    );
  };

  componentDidMount() {
    this.getFormFields();
  }
  redirectState() {
    this.firstSend();
    this.setState({
      redirect: true
    });
  }

  RedirectToHome() {
    return (
      <div
        style={{
          margin: "50px",
          height: "50vh"
        }}
      >
        <h2>DATA SUBMITTED</h2>
        <Link to="../dashbord">Goto Home Page</Link>
      </div>
    );
  }
  render() {
    if (this.state.redirect) {
      return <this.RedirectToHome />;
    }
    if (this.state.sliceArray.length < 1) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else {
      return this.AddNewPost();
    }
  }
}
