import React, { Component } from "react";
import { NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class ListFormData extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.getData = this.getData.bind(this);

    this.state = {
      limit: 20,
      page: 1,
      patientData: [],
      open: false,
      open2: false,
      nameAndId: {
        fields: {}
      },
      nameAndId2: {
        fields: {}
      }
    };
  }

  async getData() {
    let page = this.state.page;
    let limit = this.state.limit;
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/getincomplist/${page}/${limit}`,

      headers: { Authorization: `Bearer ${token}` }
    });

    this.setState({
      patientData: response.data.data,
      token: token
    });
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  toggle2() {
    this.setState({
      open2: !this.state.open2
    });
  }

  ModalPopUp(data) {
    this.setState({
      open: !this.state.open,
      nameAndId: data
    });
  }

  ModalPopUp2(data) {
    this.setState({
      open2: !this.state.open2,
      nameAndId2: data
    });
  }

  deletePatient = async id => {
    let token = this.state.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/forms/delete/${id}`, config)
      .catch(function(error) {});
    if (response.data.statusCode === 200) {
      this.getData();
      this.toggle();
    }
  };

  RenderTable = () => {
    return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Active Users</h6>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  #
                </th>
                <th scope="col" className="border-0">
                  Patient Id
                </th>
                <th scope="col" className="border-0">
                  Hospital Id
                </th>
                <th scope="col" className="border-0">
                  Cardiologist
                </th>

                <th scope="col" style={{ width: "30%" }} className="border-0">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.patientData.map((data, no) => {
                return (
                  <tr>
                    <td>{no + 1}</td>
                    <td>{data.fields.patient_id_819}</td>
                    <td>{data.fields.hospital_id_998}</td>
                    <td>
                      {data.fields.name_of_the_cardiologist_in_charge_611}
                    </td>

                    <td>
                      <Row>
                        <Col>
                          <Link to={`patientdata/${data._id}`}>
                            <Button theme="success" className="mb-2 mr-1">
                              Edit <i class="fas fa-edit"></i>
                            </Button>
                          </Link>
                        </Col>
                        <Col>
                          <Button
                            theme="danger"
                            className="mb-2 mr-1"
                            onClick={() => this.ModalPopUp(data)}
                          >
                            Delete <i class="fas fa-trash-alt"></i>
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <Modal open={this.state.open} toggle={this.toggle}>
              <ModalHeader>
                Are you sure you want to delete this item?
              </ModalHeader>
              <ModalBody>
                {this.state.nameAndId.fields.name} will be deleted <br />
                <Button
                  theme="danger"
                  className="mb-2 mr-1"
                  onClick={() => this.deletePatient(this.state.nameAndId._id)}
                >
                  Yes, Delete
                </Button>
                <Button
                  theme="success"
                  className="mb-2 mr-1"
                  onClick={this.toggle}
                >
                  Cancel
                </Button>
              </ModalBody>
            </Modal>
          </div>
        </CardBody>
      </Card>
    );
  };

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="List Form"
            subtitle="List Form Fields"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col md="12">
            <this.RenderTable />
          </Col>
        </Row>
      </Container>
    );
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    return this.AddNewPost();
  }
}
